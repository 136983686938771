import { ChainId, CurrencyAmount, Token } from '@baseswapfi/sdk-core';
import { BigNumberish } from 'ethers';
import ms from 'ms';
import { Chain as WagmiChain, arbitrum, base, optimism, soneiumMinato } from 'wagmi/chains';
import { CHAIN_MAIN } from '~/constants';
import {
  DAI_ARBITRUM_ONE,
  DAI_OPTIMISM,
  USDC_ARBITRUM,
  USDC_BASE,
  USDC_OPTIMISM,
  USDC_SONEIUM_TESTNET,
} from '~/constants/tokens';
import { UniverseChainId } from './chainsUniverse';
import { ONE_MINUTE_MS } from './time';

export const AVERAGE_L1_BLOCK_TIME = ms(`12s`);

/** Address that represents native currencies on ETH, Arbitrum, etc. */
export const DEFAULT_NATIVE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const DEFAULT_RETRY_OPTIONS: RetryOptions = { n: 10, minWait: 250, maxWait: 1000 };

export const DEFAULT_MS_BEFORE_WARNING = ONE_MINUTE_MS * 10;

// const SUPPORTED_CHAINS = [ChainId.BASE]
//   ;
const SUPPORTED_CHAINS = (() => {
  switch (CHAIN_MAIN) {
    case 34443: // Mode network
      return [ChainId.MODE];
    case 8453: // Base network
      return [ChainId.BASE];
    case 10: // Base network
      return [ChainId.OPTIMISM];
    case 42161: // Base network
      return [ChainId.ARBITRUM];
    case 1946: // Soneium testnet
      return [ChainId.SONEIUM_TESTNET];
    default:
      throw new Error(`Unsupported chain ID: ${CHAIN_MAIN}`);
  }
})();

export const CHAIN_IDS_TO_ROUTE_NAMES = (() => {
  switch (CHAIN_MAIN) {
    case 34443: // Mode network
      return {
        [ChainId.MODE]: 'mode',
      };
    case 8453: // Base network
      return {
        [ChainId.BASE]: 'base',
      };
    case 10: // Base network
      return {
        [ChainId.OPTIMISM]: 'optimism',
      };
    case 42161: // Arbitrum network
      return {
        [ChainId.ARBITRUM]: 'arbitrum',
      };
    case 1946: // Arbitrum network
      return {
        [ChainId.SONEIUM_TESTNET]: 'soneium testnet', // return to this as well
      };
    default:
      throw new Error(`Unsupported chain ID: ${CHAIN_MAIN}`);
  }
})();

export function isSupportedChain(chainId: number) {
  return !!chainId && SUPPORTED_CHAINS.includes(chainId);
}

export type InterfaceChainId = UniverseChainId;

export enum RPCType {
  Public = 'public',
  Private = 'private',
  PublicAlt = 'public_alternative',
}

export enum NetworkLayer {
  L1,
  L2,
}

export interface RetryOptions {
  n: number;
  minWait: number;
  maxWait: number;
}

// export type InterfaceGqlChain = Exclude<BackendChainId, BackendChainId.UnknownChain>

export interface BackendChain {
  chain: ChainId;
  /**
   * Set to false if the chain is not available on Explore.
   */
  backendSupported: boolean;
  /**
   * Set to true if the chain does not have a specific GQLChain. Eg: Optimism-Goerli.
   */
  isSecondaryChain: boolean;
  /**
   * Used for spot token prices
   */
  nativeTokenBackendAddress: string | undefined;
}

export interface UniverseChainInfo extends WagmiChain {
  readonly id: UniverseChainId;
  //readonly sdkId: UniswapSDKChainId
  readonly assetRepoNetworkName: string | undefined; // Name used to index the network on this repo: https://github.com/Uniswap/assets/
  readonly backendChain: BackendChain;
  readonly blockPerMainnetEpochForChainId: number;
  readonly blockWaitMsBeforeWarning: number | undefined;
  readonly bridge?: string;
  readonly chainPriority: number; // Higher priority chains show up first in the chain selector
  readonly docs: string;
  // readonly elementName: ElementNameType
  readonly explorer: {
    name: string;
    url: string;
    apiURL?: string;
  };
  readonly helpCenterUrl: string | undefined;
  readonly infoLink: string;
  readonly infuraPrefix: string | undefined;
  readonly interfaceName: string;
  readonly label: string;
  // readonly logo?: ImageSourcePropType
  readonly nativeCurrency: {
    name: string; // 'Goerli ETH',
    symbol: string; // 'gorETH',
    decimals: number; // 18,
    address: string; // '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
    explorerLink?: string; // Special override for native ETH explorer link
  };
  readonly networkLayer: NetworkLayer;
  readonly pendingTransactionsRetryOptions: RetryOptions | undefined;
  readonly spotPriceStablecoinAmount: CurrencyAmount<Token>;
  readonly stablecoins: Token[];
  readonly statusPage?: string;
  readonly supportsClientSideRouting: boolean;
  readonly supportsGasEstimates: boolean;
  readonly urlParam: string;
  readonly wrappedNativeCurrency: {
    name: string; // 'Wrapped Ether',
    symbol: string; // 'WETH',
    decimals: number; // 18,
    address: string; // '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6'
  };
}

export const UNIVERSE_CHAIN_INFO: Record<UniverseChainId, UniverseChainInfo> = {
  [UniverseChainId.SoneiumTestnet]: {
    ...soneiumMinato,
    // still need to update some of the below junk
    id: UniverseChainId.SoneiumTestnet,
    // sdkId: UniswapSDKChainId.ARBITRUM_ONE,
    assetRepoNetworkName: 'soneium',
    backendChain: {
      chain: ChainId.SONEIUM_TESTNET,
      backendSupported: true,
      isSecondaryChain: false,
      nativeTokenBackendAddress: undefined,
    },
    blockPerMainnetEpochForChainId: 46,
    blockWaitMsBeforeWarning: DEFAULT_MS_BEFORE_WARNING,
    bridge: 'https://bridge.arbitrum.io/',
    chainPriority: 1,
    docs: 'https://offchainlabs.com/',
    // elementName: ElementName.ChainArbitrum,
    explorer: {
      name: 'Arbiscan',
      url: 'https://arbiscan.io/',
      apiURL: 'https://api.arbiscan.io',
    },
    helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
    infoLink: 'https://app.uniswap.org/explore/tokens/arbitrum',
    infuraPrefix: 'arbitrum-mainnet',
    interfaceName: 'arbitrum',
    label: 'Arbitrum',
    // logo: ARBITRUM_LOGO,
    nativeCurrency: {
      name: 'Soneium ETH',
      symbol: 'ETH',
      decimals: 18,
      address: DEFAULT_NATIVE_ADDRESS,
      explorerLink: 'https://arbiscan.io/chart/etherprice',
    },
    networkLayer: NetworkLayer.L2,
    pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS,
    spotPriceStablecoinAmount: CurrencyAmount.fromRawAmount(USDC_SONEIUM_TESTNET, 10_000e6),
    stablecoins: [USDC_SONEIUM_TESTNET],
    statusPage: undefined,
    supportsClientSideRouting: true,
    supportsGasEstimates: true,
    urlParam: 'arbitrum',
    rpcUrls: {
      [RPCType.Public]: { http: [process.env.NEXT_PUBLIC_SONEIUM_TESTNET_RPC] },
      default: { http: ['https://rpc.minato.soneium.org/'] },
      fallback: { http: ['https://rpc.minato.soneium.org/'] },
      appOnly: {
        http: [process.env.NEXT_PUBLIC_SONEIUM_TESTNET_RPC],
      },
      [RPCType.PublicAlt]: { http: ['https://rpc.minato.soneium.org/'] },
    },
    wrappedNativeCurrency: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0x4200000000000000000000000000000000000006',
    },
  } as const satisfies UniverseChainInfo,

  [UniverseChainId.ArbitrumOne]: {
    ...arbitrum,
    id: UniverseChainId.ArbitrumOne,
    // sdkId: UniswapSDKChainId.ARBITRUM_ONE,
    assetRepoNetworkName: 'arbitrum',
    backendChain: {
      chain: ChainId.ARBITRUM,
      backendSupported: true,
      isSecondaryChain: false,
      nativeTokenBackendAddress: undefined,
    },
    blockPerMainnetEpochForChainId: 46,
    blockWaitMsBeforeWarning: DEFAULT_MS_BEFORE_WARNING,
    bridge: 'https://bridge.arbitrum.io/',
    chainPriority: 1,
    docs: 'https://offchainlabs.com/',
    // elementName: ElementName.ChainArbitrum,
    explorer: {
      name: 'Arbiscan',
      url: 'https://arbiscan.io/',
      apiURL: 'https://api.arbiscan.io',
    },
    helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
    infoLink: 'https://app.uniswap.org/explore/tokens/arbitrum',
    infuraPrefix: 'arbitrum-mainnet',
    interfaceName: 'arbitrum',
    label: 'Arbitrum',
    // logo: ARBITRUM_LOGO,
    nativeCurrency: {
      name: 'Arbitrum ETH',
      symbol: 'ETH',
      decimals: 18,
      address: DEFAULT_NATIVE_ADDRESS,
      explorerLink: 'https://arbiscan.io/chart/etherprice',
    },
    networkLayer: NetworkLayer.L2,
    pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS,
    spotPriceStablecoinAmount: CurrencyAmount.fromRawAmount(USDC_ARBITRUM, 10_000e6),
    stablecoins: [USDC_ARBITRUM, DAI_ARBITRUM_ONE],
    statusPage: undefined,
    supportsClientSideRouting: true,
    supportsGasEstimates: true,
    urlParam: 'arbitrum',
    rpcUrls: {
      [RPCType.Public]: { http: [process.env.NEXT_PUBLIC_ARBITRUM_RPC] },
      default: { http: ['https://arb1.arbitrum.io/rpc'] },
      fallback: { http: ['https://arbitrum.public-rpc.com'] },
      appOnly: {
        http: [process.env.NEXT_PUBLIC_ARBITRUM_RPC],
      },
      [RPCType.PublicAlt]: { http: ['https://arb1.arbitrum.io/rpc'] },
    },
    wrappedNativeCurrency: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    },
  } as const satisfies UniverseChainInfo,
  [UniverseChainId.Optimism]: {
    ...optimism,
    id: UniverseChainId.Optimism,
    // sdkId: UniswapSDKChainId.OPTIMISM,
    assetRepoNetworkName: 'optimism',
    backendChain: {
      chain: ChainId.OPTIMISM,
      backendSupported: true,
      isSecondaryChain: false,
      nativeTokenBackendAddress: undefined,
    },
    blockPerMainnetEpochForChainId: 6,
    blockWaitMsBeforeWarning: 1500000,
    bridge: 'https://app.optimism.io/bridge',
    chainPriority: 2,
    docs: 'https://optimism.io/',
    //elementName: ElementName.ChainOptimism,
    explorer: {
      name: 'OP Etherscan',
      url: 'https://optimistic.etherscan.io/',
      apiURL: 'https://api-optimistic.etherscan.io',
    },
    helpCenterUrl:
      'https://help.uniswap.org/en/collections/3137778-uniswap-on-optimistic-ethereum-oξ',
    infoLink: 'https://app.uniswap.org/explore/tokens/optimism',
    infuraPrefix: 'optimism-mainnet',
    interfaceName: 'optimism',
    label: 'Optimism',
    // logo: OPTIMISM_LOGO,
    nativeCurrency: {
      name: 'Optimistic ETH',
      symbol: 'ETH',
      decimals: 18,
      address: DEFAULT_NATIVE_ADDRESS,
      explorerLink: 'https://optimistic.etherscan.io/chart/etherprice',
    },
    networkLayer: NetworkLayer.L2,
    pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS,
    rpcUrls: {
      [RPCType.Public]: { http: [process.env.NEXT_PUBLIC_OPTIMISM_RPC] },
      [RPCType.PublicAlt]: { http: ['https://mainnet.optimism.io'] },
      default: { http: ['https://mainnet.optimism.io/'] },
      fallback: { http: ['https://rpc.ankr.com/optimism'] },
      appOnly: { http: [process.env.NEXT_PUBLIC_OPTIMISM_RPC] },
    },
    spotPriceStablecoinAmount: CurrencyAmount.fromRawAmount(DAI_OPTIMISM, 10_000e18),
    stablecoins: [USDC_OPTIMISM, DAI_OPTIMISM],
    statusPage: 'https://optimism.io/status',
    supportsClientSideRouting: true,
    supportsGasEstimates: true,
    urlParam: 'optimism',
    wrappedNativeCurrency: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0x4200000000000000000000000000000000000006',
    },
  } as const satisfies UniverseChainInfo,
  [UniverseChainId.Base]: {
    ...base,
    id: UniverseChainId.Base,
    backendChain: {
      chain: ChainId.BASE,
      backendSupported: true,
      isSecondaryChain: false,
      nativeTokenBackendAddress: undefined,
    },
    blockPerMainnetEpochForChainId: 6,
    blockWaitMsBeforeWarning: 1500000,
    bridge: 'https://bridge.base.org/deposit',
    chainPriority: 4,
    docs: 'https://docs.base.org/docs/',
    explorer: {
      name: 'BaseScan',
      url: 'https://basescan.org/',
      apiURL: 'https://api.basescan.org',
    },
    helpCenterUrl: undefined,
    infoLink: 'https://app.uniswap.org/explore/tokens/base',
    interfaceName: 'base',
    label: 'Base',
    // logo: BASE_LOGO,
    nativeCurrency: {
      name: 'Base ETH',
      symbol: 'ETH',
      decimals: 18,
      address: DEFAULT_NATIVE_ADDRESS,
      explorerLink: 'https://basescan.org/chart/etherprice',
    },
    networkLayer: NetworkLayer.L2,
    pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS,
    statusPage: 'https://status.base.org/',
    supportsClientSideRouting: true,
    supportsGasEstimates: true,
    urlParam: 'base',
    rpcUrls: {
      [RPCType.Public]: { http: [process.env.NEXT_PUBLIC_BASE_RPC] },
      default: { http: ['https://mainnet.base.org/'] },
      fallback: { http: ['https://1rpc.io/base', 'https://base.meowrpc.com'] },
      appOnly: { http: [process.env.NEXT_PUBLIC_BASE_RPC] },
    },
    spotPriceStablecoinAmount: CurrencyAmount.fromRawAmount(USDC_BASE, 10_000e6),
    assetRepoNetworkName: 'base',
    stablecoins: [USDC_BASE],
    infuraPrefix: 'base-mainnet',
    wrappedNativeCurrency: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0x4200000000000000000000000000000000000006',
    },
  } as const satisfies UniverseChainInfo,
};

// Some code from the web app uses chainId types as numbers
// This validates them as coerces into SupportedChainId
export function toSupportedChainId(chainId?: BigNumberish): UniverseChainId | null {
  // Support Goerli for testing
  // const ids = isTestEnv()
  //   ? [UniverseChainId.Base_Goerli, ...WALLET_SUPPORTED_CHAIN_IDS]
  //   : WALLET_SUPPORTED_CHAIN_IDS;

  const ids = SUPPORTED_CHAINS;

  if (!chainId || !ids.map((c) => c.toString()).includes(chainId.toString())) {
    return null;
  }
  return parseInt(chainId.toString(), 10) as UniverseChainId;
}

export type SupportedInterfaceChainId = InterfaceChainId;

export function getChain(options: { chainId: ChainId }): UniverseChainInfo;
export function getChain(options: { chainId?: ChainId; withFallback: true }): UniverseChainInfo;
export function getChain(options: {
  chainId?: ChainId;
  withFallback?: boolean;
}): UniverseChainInfo | undefined;
export function getChain({
  chainId,
  withFallback,
}: {
  chainId?: ChainId;
  withFallback?: boolean;
}): UniverseChainInfo | undefined {
  return chainId
    ? UNIVERSE_CHAIN_INFO[chainId]
    : withFallback
    ? UNIVERSE_CHAIN_INFO[UniverseChainId.Optimism]
    : undefined;
}

export const CHAIN_IDS_TO_NAMES = Object.fromEntries(
  Object.entries(UNIVERSE_CHAIN_INFO).map(([key, value]) => [key, value.interfaceName]),
) as { [chainId in SupportedInterfaceChainId]: string };
