import { getChain, getChainId, getChainName } from '~/lib/util/getChainId';

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 10 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 10;
export const BACKEND_API_BASE_URL = 'https://api-backend-0191c757fded.herokuapp.com';
export const CHAIN_MAIN = getChainId();
export const CHAIN_MAIN_NAME = getChainName();
export const CHAIN_MAIN_CHAIN_NAME = getChain();
