// 'use client';
// import { useAccount, useChainId, usePublicClient, useWalletClient } from 'wagmi';

// export function useWeb3React() {
//   const account = useAccount();
//   const chainIdWagmi = useChainId();

//   const provider = usePublicClient({ chainId: chainIdWagmi });
//   const signer = useWalletClient();

//   console.log('account', account);

//   // Extract only the properties we need from account
//   const {
//     address,
//     chainId,
//     isConnected,
//     isConnecting,
//     isDisconnected,
//     isReconnecting,
//     status,
//     connector,
//   } = account;

//   return {
//     chainId: chainId,
//     account: address,
//     provider,
//     signer: signer?.data,
//     // Explicitly set account properties instead of spreading
//     address,
//     isConnected,
//     isConnecting,
//     isDisconnected,
//     isReconnecting,
//     status,
//     connector,
//   };
// }

'use client';
import { useAccount, useChainId, usePublicClient, useWalletClient } from 'wagmi';

// Attempting interface wrapper around old API
export function useWeb3React() {
  const account = useAccount();
  const chainId = useChainId();
  const provider = usePublicClient({ chainId });
  const signer = useWalletClient();
  return {
    // chainId,
    account: account?.address,
    provider,
    signer: signer?.data,
    ...account,
  };
}
