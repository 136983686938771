import { sub } from 'date-fns';

export const ONE_SECOND_MS = 1000;
export const ONE_MINUTE_MS = 60 * ONE_SECOND_MS;
export const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;
export const ONE_DAY_MS = 24 * ONE_HOUR_MS;
export const ONE_WEEK_MS = 7 * ONE_DAY_MS;

export const twentyFourHoursInMs = 24 * ONE_HOUR_MS;
export const twentyFourHoursInSecs = twentyFourHoursInMs / ONE_SECOND_MS;

export const timeNowInMs = Math.floor(Date.now() / ONE_SECOND_MS);

export const oneYearInSecs = twentyFourHoursInSecs * 365;
export const oneWeekInSecs = twentyFourHoursInSecs * 7;

export function toUnixTimestamp(jsTimestamp: number): number {
  return Math.round(jsTimestamp / ONE_SECOND_MS);
}

export function getPreviousThursday(date: Date = new Date()): Date {
  let daysSinceThursday = date.getDay() - 4;
  if (daysSinceThursday < 0) daysSinceThursday += 7;

  return sub(date, {
    days: daysSinceThursday,
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
  });
}

export function toJsTimestamp(unixTimestamp: number): number {
  return unixTimestamp * ONE_SECOND_MS;
}

export function toUtcTime(date: Date) {
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export function dateTimeLabelFor(date: Date): string {
  return date.toLocaleString(undefined, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZoneName: 'short',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
}

export default function useTime() {
  return {
    oneSecondInMs: ONE_SECOND_MS,
    oneMinInMs: ONE_MINUTE_MS,
    oneHourInMs: ONE_HOUR_MS,
    // methods
    dateTimeLabelFor,
  };
}
