export const getChainId = () => {
  const chainDef = process.env.NEXT_PUBLIC_CHAIN_MAIN;
  switch (chainDef) {
    case 'MODE':
      return 34443;
    case 'BASE':
      return 8453;
    case 'OPTIMISM':
      return 10;
    case 'ARBITRUM':
      return 42161;
    case 'SONEIUM_TESTNET':
        return 1946;
    default:
      throw new Error(`Unsupported chain: ${chainDef}`);
  }
};

export const getChainName = () => {
  const chainDef = process.env.NEXT_PUBLIC_CHAIN_MAIN;
  switch (chainDef) {
    case 'MODE':
      return 'SwapMode';
    case 'BASE':
      return 'BaseSwap';
    case 'OPTIMISM':
      return 'SuperSwap';
    case 'ARBITRUM':
      return 'Arbidex';
    case 'SONEIUM_TESTNET': 
      return 'Soneium Testnet'; 
    default:
      throw new Error(`Unsupported chain: ${chainDef}`);
  }
};

export const getChain = () => {
  const chainDef = process.env.NEXT_PUBLIC_CHAIN_MAIN;
  switch (chainDef) {
    case 'MODE':
      return 'Mode';
    case 'BASE':
      return 'Base';
    case 'OPTIMISM':
      return 'Optimism';
    case 'ARBITRUM':
      return 'Arbitrum';
    case 'SONEIUM_TESTNET':
      return 'Soneium Testnet';
    default:
      throw new Error(`Unsupported chain: ${chainDef}`);
  }
};
