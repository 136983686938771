import { ChainId, Currency, Ether, NativeCurrency, Token } from '@baseswapfi/sdk-core';
import { UniverseChainId } from '~/lib/util/chainsUniverse';
// importing universe chain Id from new, separate file here

export const NATIVE_SYMBOL: { [chainId: number]: string } = {
  [ChainId.MODE]: 'ETH',
  [ChainId.BASE]: 'ETH',
  [ChainId.OPTIMISM]: 'ETH', 
  [ChainId.ARBITRUM]: 'ETH',
  [ChainId.SONEIUM_TESTNET]: 'ETH',

};

export const WRAPPED_NATIVE_SYMBOL: { [chainId: number]: string } = {
  [ChainId.MODE]: 'WETH',
  [ChainId.BASE]: 'WETH',
  [ChainId.OPTIMISM]: 'WETH',
  [ChainId.ARBITRUM]: 'WETH',
  [ChainId.SONEIUM_TESTNET]: 'WETH',

};

export const WRAPPED_NATIVE = {
  [ChainId.MODE]: new Token(
    ChainId.MODE,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.BASE]: new Token(
    ChainId.BASE,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.OPTIMISM]: new Token(
    ChainId.OPTIMISM,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.ARBITRUM]: new Token(
    ChainId.ARBITRUM,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.SONEIUM_TESTNET]: new Token(
    ChainId.SONEIUM_TESTNET,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether',
  ),
};

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token } = {
  [ChainId.BASE]: WRAPPED_NATIVE[ChainId.BASE],
  [ChainId.MODE]: WRAPPED_NATIVE[ChainId.MODE],
  [ChainId.OPTIMISM]: WRAPPED_NATIVE[ChainId.OPTIMISM],
  [ChainId.ARBITRUM]: WRAPPED_NATIVE[ChainId.ARBITRUM],
  [ChainId.SONEIUM_TESTNET]: WRAPPED_NATIVE[ChainId.SONEIUM_TESTNET],

};

export const DEFAULT_PINNED_TOKENS = {
  [ChainId.BASE]: {
    [WRAPPED_NATIVE[ChainId.BASE].address]: WRAPPED_NATIVE[ChainId.BASE].address,
  },
  [ChainId.MODE]: {
    [WRAPPED_NATIVE[ChainId.MODE].address]: WRAPPED_NATIVE[ChainId.MODE].address,
  },
  [ChainId.OPTIMISM]: {
    [WRAPPED_NATIVE[ChainId.OPTIMISM].address]: WRAPPED_NATIVE[ChainId.OPTIMISM].address,
  },
  [ChainId.ARBITRUM]: {
    [WRAPPED_NATIVE[ChainId.ARBITRUM].address]: WRAPPED_NATIVE[ChainId.ARBITRUM].address,
  },
  [ChainId.SONEIUM_TESTNET]: {
    [WRAPPED_NATIVE[ChainId.SONEIUM_TESTNET].address]: WRAPPED_NATIVE[ChainId.SONEIUM_TESTNET].address,
  },
};

// a list of tokens by chain
export type ChainMap<T> = {
  readonly [chainId in ChainId]?: T;
};

export type ChainTokenList = ChainMap<Token[]>;

class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
    if (wrapped) return wrapped;
    throw new Error(`Unsupported chain ID: ${this.chainId}`);
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {};

  public static onChain(chainId: number): ExtendedEther {
    if (this._cachedExtendedEther[chainId]) return this._cachedExtendedEther[chainId];
    this._cachedExtendedEther[chainId] = new ExtendedEther(chainId);
    return this._cachedExtendedEther[chainId];
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {};

export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId];

  const nativeCurrency = ExtendedEther.onChain(chainId);
  cachedNativeCurrency[chainId] = nativeCurrency;

  return nativeCurrency;
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB);
  } else if (currencyA instanceof Token) {
    return false;
  } else if (currencyB instanceof Token) {
    return false;
  } else {
    return currencyA === currencyB;
  }
}

export const DAI_OPTIMISM = new Token(
  UniverseChainId.Optimism,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin',
);
export const USDC_OPTIMISM = new Token(
  UniverseChainId.Optimism,
  '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
  6,
  'USDC',
  'USD//C',
);

export const USDC_BASE = new Token(
  UniverseChainId.Base,
  '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
  6,
  'USDC',
  'USD Coin',
);

export const USDC_ARBITRUM = new Token(
  UniverseChainId.ArbitrumOne,
  '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  6,
  'USDC',
  'USD//C',
);
export const USDC_SONEIUM_TESTNET = new Token(
  UniverseChainId.SoneiumTestnet,
  '0xE9A198d38483aD727ABC8b0B1e16B2d338CF0391',
  6,
  'USDC',
  'USD//C',
);


export const DAI_ARBITRUM_ONE = new Token(
  UniverseChainId.ArbitrumOne,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin',
);
